var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center align-center",class:{ 'user-list': _vm.isUserList }},_vm._l((_vm.roleList),function(role,i){return _c('div',{key:i},[_c('div',{staticClass:"icon-container",class:{
        'grey-image': !_vm.hasRole(role.role),
        'admin-container': _vm.isAdmin,
        'user-list-container': _vm.isUserList,
        'disabled-icon': _vm.loading,
        'clickable-container': _vm.clickable,
      },attrs:{"id":_vm.isUserList
          ? ("userList" + (_vm.userId.toString()) + i)
          : _vm.userId && _vm.clickable
          ? ("clickable" + (_vm.userId.toString()) + i)
          : _vm.userId
          ? ("" + (_vm.userId.toString()) + i)
          : i.toString()},on:{"click":function($event){return _vm.modify(role.role)}}},[_c('v-img',{staticClass:"role-icon",attrs:{"src":role.icon}})],1),_c('b-tooltip',{attrs:{"target":_vm.isUserList
          ? ("userList" + (_vm.userId.toString()) + i)
          : _vm.userId && _vm.clickable
          ? ("clickable" + (_vm.userId.toString()) + i)
          : _vm.userId
          ? ("" + (_vm.userId.toString()) + i)
          : i.toString(),"triggers":"hover","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('roles.' + role.role.toLowerCase()))+" ")])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }