












































import Vue from 'vue';
import { roles } from '@/constants/roles';
import { Role } from '@/model/enums/role';

export default Vue.extend({
  name: 'Roles',
  props: {
    userId: Number,
    roles: Array as () => Role[],
    isAdmin: Boolean,
    isUserList: Boolean,
    loading: Boolean,
    clickable: { type: Boolean, default: false },
  },
  data() {
    return {
      roleList: roles,
    };
  },
  methods: {
    hasRole(role: Role): boolean {
      return this.roles.includes(role);
    },
    modify(modifiedRole: Role) {
      if (!this.loading) {
        this.$emit('modifyRole', modifiedRole);
      }
    },
  },
});
