import { Role } from '@/model/enums/role';

export const roles = [
  {
    role: Role.MANAGER,
    icon: '/images/manager.svg',
  },
  {
    role: Role.GUEST,
    icon: '/images/guest.svg',
  },
  {
    role: Role.HR,
    icon: '/images/hr.svg',
  },
  {
    role: Role.PROCUREMENT,
    icon: '/images/buyer.svg',
  },
  {
    role: Role.ADMIN,
    icon: '/images/admin.svg',
  },
  {
    role: Role.EMPLOYEE,
    icon: '/images/employee.svg',
  },
];
